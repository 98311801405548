import { CreateActionParams } from '../actions';
import { SlotService } from '../../../../utils/state/types';
import { getServiceSlotIdentifier } from '../../../../utils';
import {
  PaymentOption,
  PaymentType,
  ReservedPaymentOptionLabels,
} from '../../../../types/types';
import { SelectedPaymentOption } from '../../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { bookingsUouBookingsFormClicks1185 } from '@wix/bi-logger-wixboost-ugc/v2';
import {
  FormClickAction,
  FormClickComponent,
  FormClickActionValue,
} from '../../../../types/biLoggerTypes';

export type setServiceSelectedPaymentOptionType = (
  service: SlotService,
  paymentOption: PaymentOption,
) => void;

export function createSetServiceSelectedPaymentOption({
  context: { biLogger },
  internalActions: { updateServiceSlot },
}: CreateActionParams): setServiceSelectedPaymentOptionType {
  return async (service: SlotService, paymentOption: PaymentOption) => {
    let selectedPaymentType: SelectedPaymentOption;
    let formClickActionValue: string = FormClickActionValue.Offline;

    switch (paymentOption.type) {
      case PaymentType.SINGLE_SESSION: {
        if (paymentOption.label === ReservedPaymentOptionLabels.ONLINE) {
          selectedPaymentType = SelectedPaymentOption.ONLINE;
          formClickActionValue = FormClickActionValue.Online;
        } else {
          selectedPaymentType = SelectedPaymentOption.OFFLINE;
          formClickActionValue = FormClickActionValue.Offline;
        }
        break;
      }
      case PaymentType.BUY_PLAN:
      case PaymentType.USE_PLAN: {
        selectedPaymentType = SelectedPaymentOption.MEMBERSHIP;
        formClickActionValue = paymentOption.label;
        break;
      }
      default: {
        selectedPaymentType = SelectedPaymentOption.OFFLINE;
      }
    }

    biLogger?.report(
      bookingsUouBookingsFormClicks1185({
        component: FormClickComponent.Payment,
        action: FormClickAction.ChoosePaymentOption,
        value: formClickActionValue,
        serviceId: service.service.id,
        paymentOptions: JSON.stringify(service.service.paymentTypes),
      }),
    );

    updateServiceSlot(
      {
        selectedPaymentOption: paymentOption,
        selectedPaymentType: selectedPaymentType!,
      },
      getServiceSlotIdentifier(service.nestedSlot),
    );
  };
}
